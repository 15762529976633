import PropTypes from "prop-types"
import React, { createContext, useState } from "react"

import ContractsForm from "./ContractsForm"

export const ContractsFormContext = createContext({
  namedRates: [],
  chargesEnabled: false,
  pricingUrl: "",
  editDocumentUrl: "",
  groupId: "",
  action: "",
  pricingStructures: {
    monthly: [],
  },
  longTermStorageProducts: [],
  documentRequirements: [],
  marinaAttachments: [],
  customFieldDefinitions: [],
  m2mDiscountsEnabled: false,
  contractSigningUpdatesEnabled: false,
  templates: [],
})

export const ContractsFormContextProvider = (props) => {
  const {
    namedRates,
    chargesEnabled,
    pricingUrl,
    rateOptions: { pricingStructures },
    longTermStorageProducts,
    documentRequirements,
    marinaAttachments,
    editDocumentUrl,
    customFieldDefinitions,
    groupId,
    action,
    m2mDiscountsEnabled,
    contractSigningUpdatesEnabled,
    templates = [],
    templatesPath,
    selectedTemplateId,
    withTemplate,
    ...contractsFormProps
  } = props

  const [selectedTemplate, setSelectedTemplate] = useState(
    templates.find((template) => template.id === selectedTemplateId)
  )

  return (
    <ContractsFormContext.Provider
      value={{
        namedRates,
        pricingUrl,
        pricingStructures,
        longTermStorageProducts: longTermStorageProducts || [],
        documentRequirements: documentRequirements || [],
        marinaAttachments: marinaAttachments || [],
        editDocumentUrl,
        chargesEnabled,
        m2mDiscountsEnabled,
        groupId,
        action,
        customFieldDefinitions: customFieldDefinitions || [],
        contractSigningUpdatesEnabled,
        selectedTemplate,
        selectedTemplateId,
        setSelectedTemplate,
        templates,
        templatesPath,
        withTemplate,
      }}
    >
      <ContractsForm {...contractsFormProps} />
    </ContractsFormContext.Provider>
  )
}

ContractsFormContextProvider.propTypes = {
  namedRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      amount: PropTypes.number,
      taxRate: PropTypes.number,
    })
  ).isRequired,
  chargesEnabled: PropTypes.bool.isRequired,
  m2mDiscountsEnabled: PropTypes.bool,
  pricingUrl: PropTypes.string.isRequired,
  groupId: PropTypes.string,
  editDocumentUrl: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  rateOptions: PropTypes.shape({
    pricingStructures: PropTypes.shape({
      monthly: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      full_stay: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
  longTermStorageProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  documentRequirements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  marinaAttachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  customFieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  contractSigningUpdatesEnabled: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      previewImageUrl: PropTypes.string.isRequired,
      upatedAt: PropTypes.string,
    })
  ),
  templatesPath: PropTypes.string.isRequired,
  selectedTemplateId: PropTypes.string,
  withTemplate: PropTypes.bool,
}

export default ContractsFormContextProvider
