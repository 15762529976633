import PropTypes from "prop-types"
import React, { useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Table from "src/components/Table"

import DiscountsModal from "../DiscountsModal"
import {
  formatIncomingDiscountData,
  getAmountDisplay,
  getDisplayName,
  parseSelectedDiscount,
} from "../tabPanels/Rate/utils"

const DiscountTable = ({
  canEdit = true,
  hidePlaceholders = false,
  m2mDiscountsEnabled = false,
  billingCycle = "",
  contractStartDate = "",
}) => {
  const {
    formState: { errors },
  } = useFormContext()
  const [selectedDiscountIndex, setSelectedDiscountIndex] = useState(undefined)
  const [showDiscountModal, setShowDiscountModal] = useState(false)

  const { fields, append, remove, update } = useFieldArray({
    name: "feesAndDiscounts",
  })
  const onAddDiscount = (data) => {
    const formattedData = formatIncomingDiscountData(data)
    if (selectedDiscountIndex !== undefined) {
      update(selectedDiscountIndex, formattedData)
    } else {
      append(formattedData)
    }
  }

  const onEditClick = (index) => {
    setSelectedDiscountIndex(index)
    setShowDiscountModal(true)
  }

  const onCloseModal = () => {
    setShowDiscountModal(false)
    if (selectedDiscountIndex !== undefined) {
      setSelectedDiscountIndex(undefined)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <Form.Label htmlFor="feesAndDiscounts">Discounts</Form.Label>
        <Table autoColumnWidth fullHeight>
          <Table.Head>
            <Table.Head.Row>
              <Table.Head.Cell columnWidth="60%">Name</Table.Head.Cell>
              <Table.Head.Cell>Amount</Table.Head.Cell>
              {/* edit/delete */}
              <Table.Head.Cell />
            </Table.Head.Row>
          </Table.Head>
          <Table.Body>
            {fields.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={3}>
                  <div className="text-muted w-full text-center">
                    No discounts added
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : null}
            {fields.map((field, i) => {
              return (
                <Table.Row key={field.id}>
                  <Table.Cell>{getDisplayName(field)}</Table.Cell>
                  <Table.Cell>{getAmountDisplay(field)}</Table.Cell>
                  <Table.Cell>
                    <div className="flex flex-row justify-end gap-4">
                      {canEdit ? (
                        <a
                          className="cursor-pointer font-semibold"
                          onClick={() => onEditClick(i)}
                        >
                          Edit
                        </a>
                      ) : null}
                      <a
                        className="cursor-pointer font-semibold"
                        onClick={() => remove(i)}
                      >
                        Remove
                      </a>
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>
      {Object.values(errors?.feesAndDiscounts ?? []).map((error, i) => {
        return (
          <Form.Error key={`${i}-${error.message}`}>{error.message}</Form.Error>
        )
      })}
      <div className="flex justify-center">
        <Button
          variant="secondary"
          onClick={() => setShowDiscountModal(true)}
          disabled={
            fields.length >= 3 ||
            (billingCycle === "month_to_month" && !contractStartDate)
          }
        >
          Add Discount
        </Button>
      </div>
      {showDiscountModal ? (
        <DiscountsModal
          onSave={onAddDiscount}
          hidePlaceholders={hidePlaceholders}
          isOpen
          onClose={onCloseModal}
          selectedDiscount={parseSelectedDiscount(
            fields[selectedDiscountIndex]
          )}
          m2mDiscountsEnabled={m2mDiscountsEnabled}
          billingCycle={billingCycle}
          contractStartDate={contractStartDate}
        />
      ) : null}
    </div>
  )
}

DiscountTable.propTypes = {
  canEdit: PropTypes.bool,
  hidePlaceholders: PropTypes.bool,
  m2mDiscountsEnabled: PropTypes.bool,
  billingCycle: PropTypes.string,
  contractStartDate: PropTypes.instanceOf(Date),
}

export default DiscountTable
