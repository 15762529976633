import { yupResolver } from "@hookform/resolvers/yup"
import PropTypes from "prop-types"
import React, { useContext, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

import {
  createContractGroup,
  createContractQuote,
  updateContractGroup,
  updateContractQuote,
} from "src/api/Contracts"

import ApplyToQuotesModal from "./ApplyToQuotesModal"
import { ContractsFormContext } from "./ContractsFormContext"
import schema from "./schema"
import { getInitialValues, setSubmitParams } from "./utils"
import FormWizard from "./wizard/FormWizard"
import WizardContextProvider from "./wizard/WizardContext"

const ContractsForm = ({
  attachments,
  backUrl,
  contact,
  contactBoat,
  duplicateBoatCount,
  duplicateId,
  form: defaultFormValues,
  isGroup,
  outstandingCount,
  title,
  unsentCount,
  view,
}) => {
  const {
    groupId,
    action,
    m2mDiscountsEnabled,
    selectedTemplateId,
    withTemplate,
  } = useContext(ContractsFormContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const defaultValues = useMemo(
    () =>
      getInitialValues(
        defaultFormValues,
        attachments,
        contact,
        contactBoat,
        isGroup,
        m2mDiscountsEnabled,
        selectedTemplateId,
        withTemplate
      ),
    [
      defaultFormValues,
      attachments,
      contact,
      contactBoat,
      isGroup,
      m2mDiscountsEnabled,
      selectedTemplateId,
      withTemplate,
    ]
  )

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  })

  const getEndpoint = (data) => {
    const formattedBaseParams = setSubmitParams(data)
    if (isGroup) {
      // we're saving a contract group
      const params = {
        ...formattedBaseParams,
        name: data.name,
        ...(data.copyBoats ? { copy_boats: data.copyBoats } : {}),
      }
      if (!defaultFormValues?.id || duplicateId) {
        return createContractGroup(params, duplicateId)
      }
      return updateContractGroup(params, defaultFormValues.id)
    } else {
      // we're saving a contract quote
      const params = {
        ...formattedBaseParams,
        contact_id: data.contact_id,
        contact_boat_id: data.contact_boat_id,
      }
      if (!defaultFormValues?.id) {
        return createContractQuote(params, groupId)
      }
      return updateContractQuote(params, groupId)
    }
  }

  const { mutate, isLoading } = useMutation(
    [isGroup ? "contractGroup" : "contractQuote", defaultFormValues?.id],
    getEndpoint,
    {
      // eslint-disable-next-line camelcase
      onSuccess: ({ redirect_to }) => {
        window.location.assign(new URL(redirect_to, window.location.origin))
      },
      onError: () => {
        form.setError("root.serverError", {
          message: "Something went wrong, contact support or try again.",
        })
      },
    }
  )

  const shouldShowModal =
    (unsentCount > 0 || outstandingCount > 0) && isGroup && view === "edit"

  const handleSubmitForm = (data) => {
    if (shouldShowModal) {
      setIsModalOpen(true)
    } else {
      mutate(data)
    }
  }

  return (
    <div className="container">
      <FormProvider {...form}>
        <Form
          onSubmit={form.handleSubmit(handleSubmitForm)}
          autocomplete={false}
        >
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 flex items-center justify-between border border-teal-400 bg-teal-100 p-4">
              <div className="ml-2 font-semibold">{title}</div>
              <div className="flex flex-row items-center justify-end gap-x-4">
                {form.formState.errors?.root?.serverError ? (
                  <Form.Error>
                    {form.formState.errors.root.serverError.message}
                  </Form.Error>
                ) : null}
                {duplicateId && duplicateBoatCount > 0 && (
                  <Tooltip
                    text="This will copy only completed contracts. Unsigned, unsent, declined, canceled, voided, or deleted ones are excluded."
                    placement="left"
                    variant="dark"
                  >
                    <Form.Checkbox
                      {...form.register("copyBoats")}
                      compact
                      label={`Copy ${duplicateBoatCount} Boats`}
                    />
                  </Tooltip>
                )}
                <Button
                  onClick={() => location.replace(backUrl)}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isLoading}>
                  {action}
                </Button>
                {form?.errors?.root?.serverError ? (
                  <Form.Error>
                    {form.errors.root.serverError.message}
                  </Form.Error>
                ) : null}
              </div>
            </div>
            <WizardContextProvider>
              <FormWizard title={title} action={action} />
            </WizardContextProvider>
          </div>
        </Form>
        <ApplyToQuotesModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={form.handleSubmit(mutate)}
          isLoading={isLoading}
          unsentCount={unsentCount}
          outstandingCount={outstandingCount}
        />
      </FormProvider>
    </div>
  )
}

ContractsForm.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string.isRequired,
  isGroup: PropTypes.bool.isRequired,
  duplicateId: PropTypes.string,
  duplicateBoatCount: PropTypes.number,
  backUrl: PropTypes.string.isRequired,
  attachments: PropTypes.array,
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  contactBoat: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  selectedTemplateId: PropTypes.string,
  unsentCount: PropTypes.number,
  outstandingCount: PropTypes.number,
  withTemplate: PropTypes.bool,
  view: PropTypes.string.isRequired,
}

export default ContractsForm
